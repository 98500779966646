import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import './SiteMap.scss';

// import { useLocation } from "react-router-dom";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useEffect } from 'react';

// Datas
import AreaData from '../../Components/ServicesPage/AreaData'
import LocationData from '../../Components/LocationAll/LocationData'


export default function SiteMap({ Blogs, FaqList, WebSitePages }) {
    const location = useLocation()
    const [SiteMapToArray, setSiteMapToArray] = useState([]);


    useEffect(() => {
        if (location.pathname.split('/')[2] == "website" || location.pathname.split('/')[1] == "sitemaps" || location.pathname.split('/').length <= 2 && location.pathname.split('/')[1] == "sitemap") {
            setSiteMapToArray(WebSitePages)
        } else if (location.pathname.split('/')[2] == "solutions") {
            setSiteMapToArray(WebSitePages)
        } else if (location.pathname.split('/')[2] == "blog") {
            setSiteMapToArray(Blogs)
        } else if (location.pathname.split('/')[2] == "faq") {
            setSiteMapToArray(FaqList)
        } else if (location.pathname.split('/')[2] == "socialmedia") {
            setSiteMapToArray(WebSitePages)
        }
    }, [location.pathname]);

    return (
        <div className="SiteMap">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Pomona Garage Door | Site Map</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Pomona Garage Door | Site Map" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Pomona Garage Door | Site Map" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.pomonagaragedoor.org/sitemap" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore the comprehensive sitemap for Garage Door. Find detailed links to our Garage Dooring, Repair, inspection, and repair services to ensure your home's safety and efficiency." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore the comprehensive sitemap for Garage Door. Find detailed links to our Garage Dooring, Repair, inspection, and repair services to ensure your home's safety and efficiency." data-react-helmet="true" />
                <meta name="keywords" content="Garage Dooring, Garage Door, Garage Doorinspection, Garage Door repair, Garage Door maintenance, Creosote removal, Garage Doorsafety, Garage Doorflue Repair, Soot removal, Garage Door Maintenance services" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Pomona Garage Door | Site Map" data-react-helmet="true" />
            </Helmet>
            <div className="SiteMapMenu">
                <a href="/">Website</a>
            </div>
            <div className="SiteMapDes">
                <div className='SiteMapDesBox'>
                    <a href="/">Home Page</a>
                    <a href="/contact">Contact Us</a>
                    <a href="/book">Book Garage Door Appointment Online In Pomona, California</a>
                    <a href="/services">Services</a>
                    <a href="/locations">Locations</a>
                    <a href="/blog">Blog</a>
                    <a href="/careers">Careers</a>
                    <a href="/faq">Faq</a>
                    <a href="/sitemap">Site Map</a>

                    {/* Services */}
                    <h1>Services</h1>
                    {AreaData.map((A, index) =>
                        <a className="Header1BoxMenuBox" href={`${A.Path}`}>{A.Title} in Pomona, CA</a>
                    )}

                    {/* Reviews */}
                    <h1>Locations</h1>
                    {LocationData.map((A, index) =>
                        <a href={`/reviews/${A.City.replace(' ', '').toLowerCase()}`} >{A.City} Garage Door</a>
                    )}

                    {/* faq */}
                    <h1>Faqs</h1>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_do_I_know_if_my_garage_door_needs_repair_in_Pomona_California_0">How do I know if my garage door needs repair in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Can_I_repair_a_garage_door_myself_in_Pomona_California_1">Can I repair a garage door myself in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_long_does_a_typical_garage_door_repair_take_in_Pomona_California_2">How long does a typical garage door repair take in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_are_common_garage_door_problems_in_Pomona_California_3">What are common garage door problems in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_much_does_garage_door_repair_cost_in_Pomona_California_4">How much does garage door repair cost in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_types_of_garage_door_services_are_offered_in_Pomona_California_5">What types of garage door services are offered in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Why_is_regular_maintenance_important_for_garage_doors_in_Pomona_California_6">Why is regular maintenance important for garage doors in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Can_I_schedule_same-day_garage_door_service_in_Pomona_California_7">Can I schedule same-day garage door service in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_should_I_look_for_in_a_garage_door_service_provider_in_Pomona_California_8">What should I look for in a garage door service provider in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_often_should_I_have_my_garage_door_serviced_in_Pomona_California_9">How often should I have my garage door serviced in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_causes_garage_door_springs_to_break_in_Pomona_California_10">What causes garage door springs to break in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_can_I_tell_if_my_garage_door_spring_is_broken_in_Pomona_California_11">How can I tell if my garage door spring is broken in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Is_it_safe_to_operate_a_garage_door_with_a_broken_spring_in_Pomona_California_12">Is it safe to operate a garage door with a broken spring in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_long_does_it_take_to_replace_a_broken_garage_door_spring_in_Pomona_California_13">How long does it take to replace a broken garage door spring in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_is_the_cost_to_replace_a_garage_door_spring_in_Pomona_California_14">What is the cost to replace a garage door spring in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_causes_a_garage_door_to_go_off_track_in_Pomona_California_15">What causes a garage door to go off track in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_should_I_do_if_my_garage_door_goes_off_track_in_Pomona_California_16">What should I do if my garage door goes off track in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_is_a_garage_door_put_back_on_track_in_Pomona_California_17">How is a garage door put back on track in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Can_I_prevent_my_garage_door_from_going_off_track_in_Pomona_California_18">Can I prevent my garage door from going off track in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_much_does_it_cost_to_fix_a_garage_door_that_is_off_track_in_Pomona_California_19">How much does it cost to fix a garage door that is off track in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_are_the_best_materials_for_residential_garage_doors_in_Pomona_California_20">What are the best materials for residential garage doors in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_do_I_choose_the_right_style_of_garage_door_for_my_home_in_Pomona_California_21">How do I choose the right style of garage door for my home in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Are_insulated_garage_doors_necessary_in_Pomona_California_22">Are insulated garage doors necessary in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_often_should_I_replace_my_residential_garage_door_in_Pomona_California_23">How often should I replace my residential garage door in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Can_I_customize_my_residential_garage_door_in_Pomona_California_24">Can I customize my residential garage door in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_types_of_garage_door_openers_are_available_in_Pomona_California_25">What types of garage door openers are available in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_do_I_know_if_I_need_to_replace_my_garage_door_opener_in_Pomona_California_26">How do I know if I need to replace my garage door opener in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Can_I_install_a_garage_door_opener_myself_in_Pomona_California_27">Can I install a garage door opener myself in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_are_the_benefits_of_a_smart_garage_door_opener_in_Pomona_California_28">What are the benefits of a smart garage door opener in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_much_does_it_cost_to_install_a_garage_door_opener_in_Pomona_California_29">How much does it cost to install a garage door opener in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_factors_should_I_consider_when_installing_a_new_garage_door_in_Pomona_California_30">What factors should I consider when installing a new garage door in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_long_does_it_take_to_install_a_new_garage_door_in_Pomona_California_31">How long does it take to install a new garage door in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Can_I_replace_my_garage_door_myself_in_Pomona_California_32">Can I replace my garage door myself in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_is_the_cost_of_installing_a_new_garage_door_in_Pomona_California_33">What is the cost of installing a new garage door in Pomona California</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Are_there_any_local_regulations_for_garage_door_installation_in_Pomona_California_34">Are there any local regulations for garage door installation in Pomona California</a>

                    {/* blog */}
                    <h1>Blog</h1>
                    <a className='Header1BoxMenuBox' href="/blog/understanding_and_addressing_garage_door_broken_springs_in_Pomona,_ca:_safety,_repair,_and_prevention_3">Comprehensive Guide to Garage Door Repair in Pomona, CA: Keeping Your Home Secure and Functional</a>
                    <a className='Header1BoxMenuBox' href="/blog/the_ultimate_guide_to_garage_door_installation_in_Pomona,_ca:_transforming_your_home’s_curb_appeal_and_security_2">Comprehensive Guide to Garage Door Services in Pomona, CA: Ensuring Safety, Functionality, and Longevity</a>
                    <a className='Header1BoxMenuBox' href="/blog/comprehensive_guide_to_garage_door_services_in_Pomona,_ca:_ensuring_safety,_functionality,_and_longevity_1">The Ultimate Guide to Garage Door Installation in Pomona, CA: Transforming Your Home’s Curb Appeal and Security</a>
                    <a className='Header1BoxMenuBox' href="/blog/comprehensive_guide_to_garage_door_repair_in_Pomona,_ca:_keeping_your_home_secure_and_functional_0">Understanding and Addressing Garage Door Broken Springs in Pomona, CA: Safety, Repair, and Prevention</a>
                </div>



            </div>
            <NavigatorPath />
        </div>
    )
}
